'use client'
import { type FC } from 'react'
import { useRouter } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'
import { autoComplete, inputPattern } from '@/utils'
import { Button, Input } from '..'

type TypeFormInputs = {
  email: string,
  password: string
}

export const FormAuthResetPassword: FC = () => {
  const { register, handleSubmit } = useForm<TypeFormInputs>()
  const { push } = useRouter()
  const { t } = useTranslator()

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData) => {
    try {
      const res = await fetch('/api/auth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      if (!res.ok) throw new Error('FormAuthResetPasword submit failed')
      if (res.status === 200) {
        push('/login')
      }
    } catch (e) {
      console.warn('Reset password submit - Catch e', e)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <legend><h1>Reset password</h1></legend>
        <Input type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.current} icon={'password'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
        <Button type='submit'>Reset password</Button>
      </Form>
    </>
  )
}

const Form = styled.form`
`
