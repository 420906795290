'use client'
import { type FC } from 'react'
import styled from 'styled-components'
import { FormAuthLogin } from './forms/FormAuthLogin'
import { FormAuthSignUp } from './forms/FormAuthSignUp'
import { FormAuthResetPassword } from './forms/FormAuthResetPassword'
import { usePathname } from 'next/navigation'
import { mq, vw, font40_46 } from '@/styles'

type Props = {}

const formAuthByType = {
  'login': () => <FormAuthLogin />,
  'signup': () => <FormAuthSignUp />,
  'reset-pass': () => <FormAuthResetPassword />
}

export const SectionAuth: FC<Props> = () => {
  const pathname = usePathname()

  return <Section>
    {formAuthByType[pathname.split('/')?.[1]]()}
  </Section>
}

const Section = styled.section`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  form {
    width: 100%;
    max-width: 500px;
  }

  legend {
    padding: 0 ${vw(4.25, 'mobile')};
    text-align: left;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(4.25, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(12.5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 12.5px;
    }
  }

  h1 {
    ${font40_46(true, 600)}
    letter-spacing: 1.06px;
    margin-bottom: ${vw(20, 'mobile')};
    text-align: left;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font40_46(false, 600)}
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }

  button {
    &[type='submit'] {
      margin: ${vw(50, 'mobile')} ${vw(4.25, 'mobile')} 0;
      text-align: left;

      ${mq.greaterThan('nexus7')} {
        margin: ${vw(50, 'tablet')} ${vw(4.25, 'tablet')} 0;
      }

      ${mq.greaterThan('tablet')} {
        margin: ${vw(50, 'desktop')} ${vw(12.5, 'desktop')} 0;
      }

      ${mq.greaterThan('desktop')} {
        margin: 50px 12.5px 0;
      }
    }
  }
`
