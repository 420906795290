'use client'
import { type FC } from 'react'
import { useRouter } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'
import { autoComplete, inputPattern, cookie } from '@/utils'
import { Button, Input } from '..'

type TypeFormInputs = {
  email: string,
  password: string
}

export const FormAuthSignUp: FC = () => {
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm<TypeFormInputs>()
  const { push } = useRouter()
  const { t } = useTranslator()

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData) => {
    try {
      const res = await fetch('/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      if (!res.ok) throw new Error('FormAuthSignup submit failed')
      if (res.status === 200) {
        console.log('res', res)
        const data = await res.json()
        console.log('data', data)
        cookie.set('DY_TEMPUS_USER', JSON.stringify(data), { maxAge: getMaxAge(data.exp) })
        push('/')
      }
    } catch (e) {
      console.warn('Login submit - Catch e', e)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <legend><h1>Welcome!</h1></legend>
        <Input type='email' name='email' register={register} label={t('form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required={{
          required: t('form.errors.required') as string,
          pattern: {
            value: inputPattern.email,
            message: t('form.errors.pattern_email') as string
          }
        }} error={formErrors.email} />
        <Input type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.current} icon={'password'} required={{
          required: t('form.errors.required') as string,
          pattern: {
            value: inputPattern.password,
            message: t('form.errors.pattern_password') as string
          }
        }} />
        <Button type='submit'>Sign up</Button>
      </Form>
    </>
  )
}

const Form = styled.form`
`

const getMaxAge = (dateString: string): number => {
  // Parse the date string into a Date object
  const date = new Date(dateString)

  // Calculate the time difference between the parsed date and the current date
  const currentTime = new Date()
  const timeDifferenceMilliseconds = date.getTime() - currentTime.getTime()

  // Convert the time difference to seconds for maxAge
  const maxAgeSeconds = Math.floor(timeDifferenceMilliseconds / 1000)
  return maxAgeSeconds
}
